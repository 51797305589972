import React from 'react';
import ThreeContainer from '../components/threeContainer'

//Assets
const gradientOne = [[57,1,100], [255,238,0], [0,159,255], [229,145,0]]
const gradientTwo = [[236,46,75], [0,93,39], [57,1,100], [246,255,0]]
const wrapperBackgroundClass = ['Purple', 'Green', 'Blue', 'Yellow']

class ThreeDBanner extends React.Component {
  
  constructor(props) {
    super(props)
  
    //Video Reference
    this.state = {
      threeColorOne: [57,1,100],
      threeColorTwo: [236,47,75],
      wrapperBackground: 'Purple'
     }
  }

  componentDidMount() {
    //var ivID = setInterval(this.colorRandomizer.bind(this),5000)
    //this.setState({intervalID : ivID})
  }

  componentWillUnmount() {
    //clearInterval(this.state.intervalID)
  }

  colorRandomizer() {
      console.log("Randomize Color")
      let pos = Math.floor(Math.random()*4)
      this.setState({threeColorOne:gradientOne[pos]})
      this.setState({threeColorTwo:gradientTwo[pos]})
      this.setState({wrapperBackground:wrapperBackgroundClass[pos]})
  }

  render() {
      return (     
        <>
        <div className={`TopBanner ${this.state.wrapperBackground}`}>
            <ThreeContainer visualType={'wave'} colorOne={this.state.threeColorOne} colorTwo={this.state.threeColorTwo} ></ThreeContainer>
            {this.props.children}
        </div>
        </>
    )
  }
};

export default ThreeDBanner;