import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Plx from 'react-plx';
import VizSensor from 'react-visibility-sensor';
import SEO from '../components/seo'


import ThreeDBanner from '../components/threeDBanner'
import MouseButton from '../components/mouseButton'
import { Helmet } from "react-helmet"

//Assets
import LaptopStroz from "../images/laptop_stroz.png"
import LaptopBj from "../images/laptop_bj.png"
//import LaptopHca from "../images/laptop_hca.png"
import LoaderImgTrans from "../images/loader-transparent.gif"
import LoaderImgWhiteS from "../images/loader-white-720.gif"


//Video
import VideoActivate from "../videos/Sequence_Activate_Homepage.mp4"
import VideoHarmonize from "../videos/Sequence_Harmonize_Homepage.mp4"
import VideoManifest from "../videos/Sequence_Manifest_Homepage.mp4"
import VideoGalavanize from "../videos/Sequence_Galavanize_Homepage.mp4"
import LaptopSFVideo from "../videos/Laptop_SF.mp4"

const sliderParallaxData = [
  {
    start: 'self',
    duration: '100vh',
    properties: [
      {
        startValue: 100,
        endValue: 100,
        unit: 'vh',
        property: 'height'
      }
    ]
  }
]



const masonaryHeaderRightParallaxData = [
  {
    start: 'self',
    duration: '60vh',
    properties: [
      {
        startValue: -5,
        endValue: 0,
        unit: 'vw',
        property: 'translateX',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  }
]


const masonaryHeaderParallaxData = [
  {
    start: 'self',
    duration: '60vh',
    properties: [
      {
        startValue: 5,
        endValue: 0,
        unit: 'vw',
        property: 'translateX',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  }
]

const masonaryHeaderCenterParallaxData = [
  {
    start: 'self',
    duration: '60vh',
    properties: [
      {
        startValue: 15,
        endValue: 0,
        unit: 'vh',
        property: 'translateY',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  }
]
const verticalLineMasonaryParallaxData = [
  {
    start: 'self',
    startOffset: '30vh',
    duration: '70vh',    
    properties: [
      {
        startValue: 0,
        endValue: 100,
        unit: 'vh',
        property: 'height',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  }
]

const laptopMiddleParallaxData = [
  {
    start: '.BottomImageMiddle',
    duration: '50vh',
    properties: [
      {
        startValue: 2.5,
        endValue: 0,
        unit: 'vh',
        property: 'translateY',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  }
]

const laptopLeftParallaxData = [
  {
    start: '.BottomImageLeft',
    duration: '50vh',
    properties: [
      {
        startValue: -10,
        endValue: 0,
        unit: 'vw',
        property: 'translateX',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  }
]

const laptopRightParallaxData = [
  {
    start: '.BottomImageRight',
    duration: '50vh',
    properties: [
      {
        startValue: 10,
        endValue: 0,
        unit: 'vw',
        property: 'translateX',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  }
]

const singlePieceImageParallaxData = [
  {
    start: 'self',
    startOffset: '10vh',
    duration: '30vh',
    properties: [
      {
        startValue: 25,
        endValue: 0,
        unit: 'vh',
        property: 'translateY',
      },
      {
        startValue: 60,
        endValue: 0,
        unit: 'deg',
        property: 'rotateX',
      }
    ],
  }
]

export default class ParallaxPage extends React.Component {
  constructor(props) {
    super(props)
     //Video
    this.VideoActivateVideoRef = React.createRef()
    this.VideoHarmonizeVideoRef = React.createRef()
    this.VideoManifestVideoRef = React.createRef()
    this.VideoGalavanizeVideoRef = React.createRef()
  }

  componentDidMount() {
  }

  render() {
    return (
    <>
        <SEO title="What We Do" description="Creative Equity Partners is a strategic brand and design development studio."/>
        <Helmet>
          <title>What We Do</title>
        </Helmet>   

        {/*Slider Block*/}
        <div className="ContentBlock Centered SliderBlock">
        <Plx parallaxData={ sliderParallaxData }>                      
          <ThreeDBanner>
            <div>
            <h4><strong>Change happens everywhere.</strong><br/>Get yours here.</h4>
            </div>
          </ThreeDBanner>
        </Plx>
        <MouseButton></MouseButton>
        </div>

        {/*CEP Block */}              
        <div className="ContentBlock Centered LaptopBlock SecondBlock" id="SecondBlock">
          <div className="HeaderWrapper">
            <h1 className="WhiteHighlight">Creative Equity Partners is a strategic brand and design development studio.</h1>
          </div>
          <h5>We believe that a diverse understanding of people, markets and industries is crucial to communicating value.</h5>
          <h5>
            <strong>We help ambitious businesses solve challenges of brand and marketing scale</strong> with focused, prioritized and adaptive strategies, creative strength, compelling language, dynamic assets, and collaborative sales and marketing enablement.
          </h5>
          <AnchorLink className="AnchorLink ArrowLink" href="#contact"><h3>Change Happens Everywhere. <br/>Get yours here.</h3></AnchorLink>
          <div className="BottomImages">
          <Plx parallaxData={ laptopRightParallaxData }>                            
                        {/*<img src={LaptopHca} className="BottomImageRight" alt="Laptop #2"/>*/}
                        <video ref={this.BannerVideoRef} autoPlay loop muted playsInline preload="metadata" className="BottomImageRight">
                                  <source src={LaptopSFVideo}/>
                                </video>

                      </Plx>

                      <Plx parallaxData={ laptopLeftParallaxData }>                            
                        <img src={LaptopStroz} className="BottomImageLeft" alt="Laptop #1"/>      
                      </Plx>      
                      <Plx parallaxData={ laptopMiddleParallaxData }>              
                        <img src={LaptopBj} className="BottomImageMiddle" alt="Laptop #3"/>
                      </Plx>        
          </div>
        </div>


      {/*Activate brand*/}
      <div className="ContentBlock Centered MasonaryBlock SingleVideo PurpleBlock">
          <div className="VerticalLineContainer">
          <Plx parallaxData={verticalLineMasonaryParallaxData}>
              <div className="VerticalLine"></div>
          </Plx> 
          </div>

          <Plx parallaxData={masonaryHeaderParallaxData}>
          <h1>Activate brand</h1>
          </Plx>
          <VizSensor 
              partialVisibility={true} 
              onChange={(isVisible) => {
                isVisible ? this.VideoActivateVideoRef.current.play() : this.VideoActivateVideoRef.current.pause()
              }}
            >
            <Plx parallaxData={singlePieceImageParallaxData}>
              <video ref={this.VideoActivateVideoRef} autoPlay loop muted playsInline className="SingleVideoVideo" poster={LoaderImgWhiteS} preload="none"><source src={VideoActivate} type="video/mp4"/></video>
            </Plx>            
          </VizSensor>
        </div>

      {/*Harmonize message and value*/}
      <div className="ContentBlock Centered MasonaryBlock RightBlock SingleVideo">
          <div className="VerticalLineContainer">
          <Plx parallaxData={verticalLineMasonaryParallaxData}>
              <div className="VerticalLine"></div>
          </Plx> 
          </div>

          <Plx parallaxData={masonaryHeaderRightParallaxData}>
          <h1>Harmonize message and value</h1>
          </Plx>

          <VizSensor 
              partialVisibility={true} 
              onChange={(isVisible) => {
                isVisible ? this.VideoHarmonizeVideoRef.current.play() : this.VideoHarmonizeVideoRef.current.pause()
              }}
            >
            <Plx parallaxData={singlePieceImageParallaxData}>
              <video ref={this.VideoHarmonizeVideoRef} autoPlay loop muted playsInline className="SingleVideoVideo" poster={LoaderImgTrans} preload="none"><source src={VideoHarmonize} type="video/mp4"/></video>
            </Plx>            
          </VizSensor>

        </div>

        {/*Manifest an evolved customer experience*/}
      <div className="ContentBlock Centered MasonaryBlock RedBlock SingleVideo">
          <div className="VerticalLineContainer">
          <Plx parallaxData={verticalLineMasonaryParallaxData}>
              <div className="VerticalLine"></div>
          </Plx> 
          </div>

          <Plx parallaxData={masonaryHeaderParallaxData}>
          <h1>Manifest an evolved customer experience</h1>
          </Plx>

          <VizSensor 
              partialVisibility={true} 
              onChange={(isVisible) => {
                isVisible ? this.VideoManifestVideoRef.current.play() : this.VideoManifestVideoRef.current.pause()
              }}
            >
            <Plx parallaxData={singlePieceImageParallaxData}>
              <video ref={this.VideoManifestVideoRef} autoPlay loop muted playsInline className="SingleVideoVideo" poster={LoaderImgWhiteS} preload="none"><source src={VideoManifest} type="video/mp4"/></video>
            </Plx>  
          </VizSensor>
        </div>

        {/*Galvanize sales opportunities around content and leadership*/}
        <div className="ContentBlock Centered MasonaryBlock RightBlock SingleVideo">
          <div className="VerticalLineContainer">
          <Plx parallaxData={verticalLineMasonaryParallaxData}>
              <div className="VerticalLine"></div>
          </Plx> 
          </div>

          <Plx parallaxData={masonaryHeaderRightParallaxData}>
          <h1>Galvanize sales opportunities around content and leadership</h1>
          </Plx>

          <VizSensor 
              partialVisibility={true} 
              onChange={(isVisible) => {
                isVisible ? this.VideoGalavanizeVideoRef.current.play() : this.VideoGalavanizeVideoRef.current.pause()
              }}
            >
            <Plx parallaxData={singlePieceImageParallaxData}>
              <video ref={this.VideoGalavanizeVideoRef} autoPlay loop muted playsInline className="SingleVideoVideo" poster={LoaderImgTrans} preload="none"><source src={VideoGalavanize} type="video/mp4"/></video>
            </Plx>  
          </VizSensor>
        </div>

        {/*Reach clients and stakeholders with sophistication, strength and consistency*/}
        <div className="ContentBlock Centered MasonaryBlock SingleVideo PurpleBlock">
          <Plx parallaxData={masonaryHeaderCenterParallaxData}>
            <h1 className="FullWidth">Reach clients and stakeholders with sophistication, strength and consistency</h1>
          </Plx>
        </div>
        </>
      )
    }
}